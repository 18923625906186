import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { chainProtocol } from '@/const/publicData/record'
const dicFormatter = (e)=>{
    let obj_1 = []
    for(let item of e){
        if(item){
            let obj_ = {
                label: item.chain,
                value: item.chain,
            }
            if(item.chainProtocolInfos){
                let obj_c = []
                for(let items of item.chainProtocolInfos){
                    obj_c.push({
                        label: items.protocol,
                        value: items.protocol
                    })
                }
                obj_.children = obj_c
                obj_1.push(obj_)
            }
        }
    }
    return obj_1
}
export const workParm = [{
    type: 'select',
    name: t('currencySetNft.name1'),
    prop: 'tenantId',
    isPostApi: 'shopList',
    postURl: '',
    postData: [],
    isPlatform: 1,//平台显示，商户不显示
    props:{
        value:'id',
        label:'name'
    },
},{
    type: 'select',
    name: t('currencySetNft.name2'),
    prop: 'appId',
    isPostApi: 'getUserappinfoList',
    postURl: '',
    postData: [],   
    props:{
        value:'appId',
        label:'appName'
    }
},{
    type: 'select',
    name: t('currencySetNft.text6'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    },
},{
    type: 'select',
    name: t('currencySetNft.text7'),
    prop: 'protocol',
    isPostApi: 'getAgreementList',
    postURl: '',
    postData: [],
    props:{
        value:'protocol',
        label:'protocol'
    }
},{
    type: 'input',
    name: t('curr.text9'),
    prop: 'contractAddress',
},{
    type: 'input',
    name: 'Token ID',
    prop: 'tokenId',
},{
    type: 'input',
    name: t('nfts.name5'),
    prop: 'storageWalletAddr',
},{
    type: 'input',
    name: t('nfts.text13'),
    prop: 'collectionName',
},{
    type: 'input',
    name: 'Owner',
    prop: 'nftOwnerAddr'
},]
export const coin = [{
    type: 'cascader',
    name: t('withdrawNft.name10'),
    prop: 'chain_protocol',
    isPostApi: 'getCoinOrxie',
    postURl: '',
    postData: [],
    dicFormatter: dicFormatter,
    props:{
        value:'chain',
        label:'chain'
    }
},{
    type:'input',
    name:t('withdrawNft.name14'),
    prop: 'contractName'
}]
export const workAddr = [{
    type:'input',
    name:t('currencySetNft.name4'),
    prop: 'walletName'
},{
    type:'input',
    name:t('otherWallatNft.text6'),
    prop: 'walletAddress'
}]

export const chain = [
...chainProtocol,
{
    type:'input',
    name:t('nfts.text13'),
    prop: 'collectionName'
}]
export const own_ = [{
    type: 'input',
    name: t('nfts.name5'),
    prop: 'storageWalletAddr',
},{
    type: 'input',
    name: 'Owner',
    prop: 'nftOwnerAddr'
},]
export const workView = [{
    type: 'input',
    name: t('nfts.name5'),
    prop: 'storageWalletAddr',
},{
    type: 'input',
    name: 'Owner',
    prop: 'nftOwnerAddr'
},]