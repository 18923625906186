<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <el-table :data="page.list" border v-loading='listLoading' height="350">
            <el-table-column prop="selection" :label="$t('currencySetNft.text8')" width="100" >
                <template #default="scope">
                    <el-checkbox size="large" @change="changeFun(scope.row)" v-model='checkItem'/>
                </template>
            </el-table-column>
            <el-table-column prop="chain" :label="$t('currencySetNft.text6')" width="100" />
            <el-table-column prop="protocol" :label="$t('currencySetNft.text7')" width="120" />
            <el-table-column prop="protocol" label="存储方式" width="100">
                 <template #default="scope">
                    {{array_[scope.row.storageType]}}
                </template>
            </el-table-column>
            <el-table-column prop="collectionName" :label="$t('nfts.text13')" />
            <el-table-column prop="contractClassName" :label="$t('nfts.name14')" />
        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import { ref,defineEmits,defineExpose } from 'vue'
    // import { coin1 } from "@/const/crud/nft/seriesList";
    import mixins from '@/views/mixins/page'
    import { chain } from '@/const/from/nft/work'
    import fromSearch from '@/components/fromSearch.vue'
    import pageCom from '@/components/pageCom.vue'

    const fromValue = ref({})
    let pageObj = mixins(['getNftcollectioninfoList'],fromValue.value)
    const {page,listLoading,sizeChange,currentChange,getList,updateOtherParm} = pageObj
    const array_ = ['','IPFS','中心化']
    
    const object_ = ref({});object_.value = chain;
    const emit = defineEmits(['getCoin'])
    const checkItem = ref(false)
    const changeFun = (e)=>{
        emit('getCoin',e)
    }
    const init_ = (e)=>{//初始化数据
        let obj_ = e;obj_.protocolType = 2;
        updateOtherParm(obj_)
        checkItem.value = false
        getList()
    }
    defineExpose({init_})
</script>

<style lang="scss" scoped>
</style>